// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../Styles/variables';

.block {
  margin: 10px auto;

  .icon {
    display: none;
    margin-left: 12px;
  }

  &:hover {
    .icon {
      display: inline-block;
    }
  }
}

.editable {
  cursor: pointer;
}

.licenseCount {
  font-weight: $sans-serif-semibold;
}

.input {
  width: 50px;
  height: 20px;
}
