// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../Styles/variables';

$border-color: #e0e0e0;

.table {
  border: 1px solid $border-color;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;

  td {
    input {
      margin: 0;
      width: calc(100% - 34px);
    }
  }

  td,
  th {
    border: 1px solid $border-color;
    padding: 8px;
    vertical-align: middle;
    height: 36px;
  }

  thead {
    th {
      background-color: $color-white;
      font-weight: $sans-serif-semibold;
    }
  }

  tbody {
    td {
      text-align: left;
    }
  }
}

.label {
  font-size: $size-6;
  color: $color-dark-gray-1;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.field {
  display: flex;
  flex-direction: row;

  input {
    margin-left: 8px;
    margin-top: 6px;
  }
}
