// Copyright © 2021 Esri. All rights reserved.

@import '../../Styles/variables';

.allReportsContainer {
  h1 {
    margin-bottom: 0.5em;
  }
}

.allReportsContainer,
.loading,
.error {
  padding: 2em;
  padding-top: 1.7em;
}

.error {
  color: $color-error;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.resultsContainer {
  margin: 24px auto;

  header {
    display: flex;
    align-items: center;
  }

  h1 {
    padding-left: 0.55em;
    text-transform: capitalize;
    margin-right: 0.5em;
  }

  table {
    border-collapse: separate;
  }

  th {
    position: sticky;
    top: 0;
    background-color: $color-white;
    text-transform: capitalize;
  }

  tr:nth-child(odd) {
    background-color: $color-light-gray-2;
  }
}
