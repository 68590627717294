// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../Styles/variables';

.error {
  color: $color-error;
  margin-top: 8px;
}

.success {
  color: $color-positive;
}

.link {
  cursor: pointer;

  &:hover {
    color: $color-dark-gray-3;
  }
}
