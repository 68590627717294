// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../../Styles/variables';

.container {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
}

.title {
  color: $color-dark-gray-3;
  font-size: $size-7;
  margin-left: 4px;
}
