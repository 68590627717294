// Copyright © 2019 3D Robotics. All rights reserved.

.name {
  cursor: pointer;

  .edit {
    opacity: 0;
    margin-left: 12px;
  }

  &:hover {
    .edit {
      opacity: 1;
    }
  }
}
