// Copyright © 2020 Esri. All rights reserved.

@import '../../Styles/variables';

.message {
  margin-left: 2em;
}

.success {
  color: $color-positive;
}

.error {
  color: $color-error;
}
