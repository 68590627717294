// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../../Styles/variables';

$container-width: 600px;
$container-height: 400px;

.container {
  background: $color-white;
  position: fixed;
  width: $container-width;
  height: $container-height;
  left: calc(50% - (#{$container-width} / 2));
  top: calc(50% - (#{$container-height} / 2));
  box-shadow: 0 20px 28px 0 $color-shadow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $color-light-gray-1;
  border-radius: $radius-medium;
  z-index: 1;

  > * {
    padding: 24px;
  }
}

header {
  border-bottom: 1px solid $color-dark-gray-1;
}

footer {
  text-align: right;
}

.error {
  color: $color-error;
}
