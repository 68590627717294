// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../Styles/variables';

.container {
  margin: 0 auto;
  padding: 48px;
  width: 50%;
  min-width: 600px;
  overflow-y: auto;
}

.button {
  margin: 8px 0 0;
  font-size: $size-7;
}
