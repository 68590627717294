// Copyright © 2019 3D Robotics. All rights reserved.

.title {
  font-weight: bold;
  padding: 4px;
}

.item {
  padding: 4px;
}

.error {
  color: #a00;
}
