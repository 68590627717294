// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../Styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.actions {
  display: flex;
}

.content {
  margin-top: 2em;
}

.tabs {
  font-weight: bold;
  margin-bottom: -1px;
  text-transform: uppercase;

  a {
    border: 1px solid transparent;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    display: inline-block;
    padding: 1em 3em;

    &.on {
      border-color: $color-light-gray-1;
      border-bottom-color: $color-white;
    }
  }
}

.data {
  border: 1px solid $color-light-gray-1;
  border-radius: 2em;
  padding: 1em 0 2em;
}

.users {
  border-top-left-radius: 0;
}
