// Copyright © 2019 3D Robotics. All rights reserved.

.logOutput {
  font-size: 85%;
  font-family: monospace;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: 1.5em;
}

.logOutput > div:nth-child(odd) {
  background-color: #f5f5f5;
}
