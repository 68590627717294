// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../Styles/variables';

.setting {
  margin: 10px auto;
}

.message {
  margin-left: 2em;
}

.success {
  color: $color-positive;
}

.error {
  color: $color-error;
}

.form {
  border: 1px solid #eee;
  padding: 8px;
}
