// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../Styles/variables';

.container {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 4px;
}

.actions {
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.status {
  &.active {
    color: $color-green;
  }

  &.inactive {
    color: $color-button-delete-text;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dateError {
  background-color: rgba(255, 0, 0, 0.2);
}

/* stylelint-disable selector-pseudo-class-no-unknown */
:global {
  .DateInput_input__small {
    font-size: 12px;
    width: 74px;
  }
}
/* stylelint-enable selector-pseudo-class-no-unknown */
