// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../Styles/variables';

.cell {
  cursor: pointer;
}

.hidden {
  display: none;
}

.error {
  color: $color-error;
}
