// Copyright © 2022 Esri. All rights reserved.

@import '../../Styles/variables';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.results {
  position: absolute;
  top: 20px;
  background: $color-white;
  width: 100%;
  height: 70vh;
  border: 1px solid $color-light-gray-1;
  border-radius: 1em;
  flex: 1;
  margin-top: 2em;
  overflow: auto;
  z-index: 1;

  table {
    width: 100%;
    table-layout: fixed;

    tr:hover {
      font-weight: 600;
    }

    td {
      padding: 1em;
      border-bottom: 1px solid $color-light-gray-1;

      &:first-child {
        text-align: center;
        width: 16px; // icon
      }
    }
  }
}

.error {
  color: $color-error;
}

.checkbox {
  display: block;
  margin-bottom: 0.5em;
}
