// Copyright © 2019 3D Robotics. All rights reserved.

// CUSTOM VARIABLES
// collection of commonly used variables
// specific to our project

// Brand Colors
$color-brand-primary: #2c9ed2;
$color-brand-secondary: #1b6385;
$color-brand-terciary: #60b2d8;
$color-brand-gradient: linear-gradient($color-brand-primary, #75b9d9); // sass-lint:disable-line no-color-literals
$color-brand-secondary-accent: #ffc564; //yellow;
$color-brand-dark: #3a3f41; // Came from slack not styleguide

// Neutral Colors
$color-black: #000;
$color-white: #fff;
$color-dark-gray-1: #3a3f41; // Main heading, body copy, backgrounds
$color-dark-gray-2: #666f73; // subheadings and supporting body copy
$color-dark-gray-3: #94a0a6;
$color-light-gray-1: #bacad1; // Borders, lines, strokes, dividers
$color-light-gray-2: #f8f8f8; // Section Background

// Utility Colors
$color-error: #d13c2c; // red
$color-error-background: #ffe4e1;
$color-positive: #2cd150; // green
$color-neutral: #2c5bd1; // blue

// Overlay Colors
$color-hero-dark-overlay: rgba(#2b2b2b, 0.85); // sass-lint:disable-line no-color-literals
$color-image-dark-overlay: rgba(#414141, 0.65); // sass-lint:disable-line no-color-literals
$color-floater: #5cc7ab;

// Domain-Specific Colors
$color-hover: rgba(#bacad2, 0.5); // sass-lint:disable-line no-color-literals

// Border Radii
$radius-small: 2px;
$radius-medium: 4px;
$radius-large: 8px;

// domain specific colors
$color-map-overlay: rgba($color-white, 0.5); // not from styleguide

// Shadows
$color-shadow: $color-image-dark-overlay;
$shadow-subtle: 0 2px 4px 0 rgba(#2b2b2b, 0.2); // sass-lint:disable-line no-color-literals

// Button states
$color-button-bg-normal: $color-brand-primary;
$color-button-bg-hover: #138bc2;
$color-button-bg-focus: #2ea7de;
$color-button-bg-active: $color-button-bg-hover;

$shadow-button-focus: -1px -1px 4px 0 rgba(42, 174, 235, .2), 1px 1px 4px 0 rgba(42, 174, 235, .2);
$shadow-button-active: inset 0 1px 5px 0 #0e6891;

$color-button-toolbar-bg-normal: $color-white;
$color-button-toolbar-bg-active: rgba(34, 157, 212, .3);

$shadow-button-toolbar-focus: -1px -1px 4px 0 rgba(44, 158, 210, .2), 1px 1px 4px 0 rgba(44, 158, 210, .2);
$shadow-button-toolbar-active: inset 0 1px 3px 0 rgba(102, 111, 115, .5);

$color-button-cancel-text: $color-light-gray-1;
$color-button-cancel-text-hover: $color-dark-gray-3;
$color-button-cancel-text-active: $color-dark-gray-2;
$color-button-cancel-text-disabled: $color-light-gray-1;

$color-button-delete-text: #e84b3a;
$color-button-delete-text-hover: #d9462f;
$color-button-delete-text-active: #ca4024;

$color-green: #2cd150;

// Mixins
@mixin hidden {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

// OVERRIDES FROM BULMA
// bulma.io variable overrides
// for more info:
// - see docs here http://bulma.io/documentation/overview/variables/
// - see source here https://github.com/jgthms/bulma/blob/master/sass/utilities/variables.sass

// colors
$primary: $color-brand-terciary;
$info: $color-brand-terciary;

// font families
$family-sans-serif: "Open Sans", "Helvetica", "Arial", sans-serif;

// font weights
$sans-serif-semibold: 600;

// font sizes
$size-1: 6rem;
$size-2: 3rem;
$size-3: 2.4rem;
$size-4: 1.6rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.8rem;
$size-8: 0.6rem;

// buttons
$button: $color-white; // button text color

// Animations

$low-key: rgba($color-brand-terciary, 0.01);
$high-key: rgba($color-brand-terciary, 0.15);

@keyframes breathing {
  0% {
    background-color: $low-key;
  }

  60% {
    background-color: $high-key;
  }
}


// BULMA VARIABLES
// @import "~bulma/sass/utilities/variables";
