// Copyright © 2020 Esri. All rights reserved.

@import '../../Styles/variables';

.container {
  margin-top: 8px;
  padding: 0 8px;
  border: 1px solid #eee;

  p {
    margin: 10px 0;
  }

  b {
    font-weight: $sans-serif-semibold;
  }

  .row {
    margin: 10px auto;
  }
}

.error {
  color: $color-error;
}

.table {
  width: 100%;

  td,
  th {
    padding: 12px;
  }

  thead {
    th {
      border-bottom: 1px solid $color-dark-gray-1;
      text-align: left;
      font-weight: $sans-serif-semibold;
    }
  }
}
