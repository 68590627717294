// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../Styles/variables';

$error-background: lighten($color-error, 10);

.messageBody {
  position: sticky;
  z-index: 10000;
  top: 0;
  display: flex;
  flex-direction: column;
  min-height: 4em;
  border-radius: 5px;
  margin-bottom: 24px;
  background: $error-background;
  max-height: 200px;
  overflow-y: auto;
  padding: 4px 12px;
  border: 1px solid $color-dark-gray-3;

  h1 {
    font-size: $size-6;
    margin: 0;
    margin-top: 20px;
    padding-left: 40px;
  }
}
