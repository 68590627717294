// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../Styles/variables';

a {
  cursor: pointer;

  &:hover {
    color: $color-dark-gray-3;
  }
}

.uploadOptions {
  margin: 12px 0 4px;

  span {
    &:nth-child(2) {
      font-weight: $sans-serif-semibold;
    }
  }
}

.uploadInstructions {
  font-size: $size-7;
  font-weight: $sans-serif-semibold;
  margin-bottom: 4px;
}

.hidden {
  display: none;
}
