.timeline {
  padding: 0 1em;
  display: grid;
  grid-template-columns: fit-content(300px) 1fr;
  gap: 5px;

  > time {
    grid-column: 1;
  }

  > span.label,
  > span.duration {
    grid-column: 2;
  }

  > span.duration,
  > time {
    color: #888;
    font-size: 85%;
    align-self: center;
  }
}
