// Copyright © 2019 3D Robotics. All rights reserved.

@import "../../Styles/variables";

.container {
  width: 300px;
  display: flex;
  flex-direction: column;
}

.logoContainer {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
}

.form {
  margin-top: 1em;
  background: $color-white;
}

.inputError {
  border: 1px solid $color-error;
}

.loginButton {
  height: 70px;
  margin: 1em 0;
  font-size: $size-6;
}

.error {
  background: $color-error;
  color: $color-white;
  text-align: center;
  padding: 16px;
  font-size: $size-7;
}
