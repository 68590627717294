// Copyright © 2019 3D Robotics. All rights reserved.

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 488px;
}

.buttonContainer {
  display: flex;
  flex-direction: row-reverse;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
}

.input {
  flex: 1;
  width: 446px;
}
