// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../Styles/variables';

.container {
  align-items: center;
  border-bottom: 1px solid $color-light-gray-1;
  display: flex;
  flex-direction: row;
  height: 60px;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 8px 24px;

  a:not(:last-child) {
    margin-right: 2em;
  }
}

.actions {
  display: flex;
  align-items: center;
}
