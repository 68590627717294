// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../../Styles/variables';

.button {
  background-color: $color-button-bg-normal;
  font-family: inherit;
  border: 0;
  border-radius: 4px;
  letter-spacing: 0;
  cursor: pointer;
  text-align: center;
  margin: 4px;
  // Flex styles center text vertically in <a> for us
  // Doesn't seem to be working...
  // display: inline-flex;
  // align-items: center;

  &:hover {
    background-color: $color-button-bg-hover;
    color: $color-white;
  }

  &:focus {
    background-color: $color-button-bg-focus;
    box-shadow: $shadow-button-focus;
    color: $color-white;
  }

  &:active {
    background-color: $color-button-bg-active;
    box-shadow: $shadow-button-active;
    color: $color-white;
  }
}

.disabled {
  opacity: .5;
  color: $color-white;
}

// SIZE

.small {
  min-height: 20px;
  min-width: 40px;
}

.medium {
  min-height: 32px;
  min-width: 100px;
}

.large {
  min-height: 54px;
  min-width: 115px;
}


//COLOR

// white on blue
.primary {
  background: $color-brand-primary;
  color: $color-white;
}

// blue on white
.inverted {
  background: $color-white;
  color: $color-brand-primary;
  border: 2px solid $color-brand-primary;
}

// grey on none
.secondary {
  background: none;
  color: $color-light-gray-1;

  &:hover {
    background: none;
    color: $color-button-cancel-text-hover;
  }

  &:active {
    background: none;
    box-shadow: none;
    color: $color-button-cancel-text-active;
  }

&:focus {
    background: none;
    box-shadow: none;
    color: $color-white;
  }

  &.disabled,
  &:disabled {
    background: none;
    color: $color-button-cancel-text-disabled;
  }
}

// white on red
.danger {
  background: none;
  color: $color-button-delete-text;

  &:hover {
    background: none;
    color: $color-button-delete-text-hover;
  }

  &:active {
    background: none;
    box-shadow: none;
    color: $color-button-delete-text-active;
  }

  &:focus {
    background: none;
    box-shadow: none;
    color: $color-white;
  }

  &.disabled,
  &:disabled {
    background: none;
    color: $color-button-delete-text;
    opacity: .5;
  }
}

// red on white with red outline
.danger-inverted {
  background: $color-white;
  color: $color-button-delete-text;
  border: 2px solid $color-button-delete-text;

  &:hover {
    background: none;
    color: $color-button-delete-text-hover;
    border: 2px solid $color-button-delete-text;
  }

  &:active {
    background: none;
    box-shadow: none;
    color: $color-button-delete-text-active;
    border: 2px solid $color-button-delete-text;
  }

  &:focus {
    background: none;
    box-shadow: none;
    color: $color-button-delete-text-active;
    border: 2px solid $color-button-delete-text;
  }
}
