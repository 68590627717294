.photoTable {
  thead th {
    padding: 8px;
  }

  td {
    padding: 8px;
  }

  pre {
    margin: 0;
  }
}

.photoId {
  font-size: 60%;
}

.exifTable td {
  max-width: 40em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.problem {
  color: red;
}
