// Copyright © 2021 Esri. All rights reserved.

@import '../../Styles/variables';

$search-width: 250px;

.container {
  display: flex;
  align-items: center;
  margin-right: 2em;
}

.input {
  display: flex;
  border: 1px solid $color-light-gray-1;
  border-radius: 0.5em;
  width: $search-width;
}

.search {
  font-size: 1rem;
  border: 0;
  outline: 0;
  flex: 1;
  padding: 0.5rem;
  border-radius: 0.5em;
}

.results {
  position: absolute;
  top: 25px;
  background: $color-white;
  width: $search-width;
  height: 40vh;
  border: 1px solid $color-light-gray-1;
  border-radius: 0.5em;
  flex: 1;
  margin-top: 2em;
  overflow: auto;
  z-index: 10001; // position over validation error

  a {
    margin-left: 0.2em;
  }

  > div {
    margin: 1em 0.5em;

    > div {
      margin-left: 1em;
    }
  }
}
