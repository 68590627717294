// Copyright © 2021 Esri. All rights reserved.

@import '../../Styles/variables';

$search-width: 250px;

.container {
  display: flex;
}

.edit {
  margin-left: 5px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

.error {
  color: $color-error;
  max-width: 200px;
}

.search {
  font-size: 1rem;
  border: 0;
  outline: 0;
  flex: 1;
  padding: 0.5rem;
  border-radius: 0.5em;
}

.results {
  position: absolute;
  top: 200px;
  background: $color-white;
  width: $search-width;
  max-height: 40vh;
  border: 1px solid $color-light-gray-1;
  border-radius: 0.5em;
  overflow: auto;
  z-index: 1;

  button {
    padding: 0.2em 0.5em;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    text-align: left;

    &:first-child {
      padding-top: 1em;
    }

    &:last-child {
      padding-bottom: 1em;
    }

    &:hover {
      background-color: #e7e7e7;
    }
  }
}
