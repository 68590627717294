// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../../Styles/variables';

.input {
  border: 1px solid $color-light-gray-1;
  border-radius: $radius-medium;
  color: $color-dark-gray-3;
  font-size: $size-6;
  font-family: inherit;
  line-height: $size-4;
  margin: 4px;
  padding: 4px 16px;

  // For `type=email`:
  &:invalid {
    border: 1px solid $color-error;
  }
}
