body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

a {
  color: #2c9ed2;
  text-decoration: none;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}
