.deletedBanner {
  padding: 1em;
  background: #c00;
  color: white;
  border-radius: 1em;
}

.deletedBanner > svg {
  margin-right: 0.5em;
}

.deletedBanner > button {
  padding: 0.25em;
  margin: 0 1em;
}
