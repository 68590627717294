// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../../Styles/variables';

.table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;

  thead {
    th {
      border-bottom: 1px solid $color-light-gray-1;
      padding: 16px;
    }
  }

  td {
    padding: 16px;
  }
}
