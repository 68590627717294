// Copyright © 2020 Esri. All rights reserved.

@import '../../Styles/variables';

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.buttonContainer {
  display: flex;
  flex-direction: row-reverse;
}

.input {
  flex: 1;
  width: 446px;
}

.active-input {
  display: flex;
}

.error {
  color: $color-error;
}
