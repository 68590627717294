// Copyright © 2019 3D Robotics. All rights reserved.

.container {
  display: flex;
  flex-direction: column;
  align-items: stretc;
  height: 100%;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.container textarea {
  flex: 1 1 auto;
  margin: 4px;
  font-family: monospace;
  font-size: 110%;
  tab-size: 2;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.statusMessage {
  flex: 1 1 auto;
}
