// Copyright © 2019 3D Robotics. All rights reserved.

@import '../../Styles/variables';

.container {
  align-items: center;
  background: $color-light-gray-2;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.navigate {
  margin-top: 2em;
  text-align: center;
}
